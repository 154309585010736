export default {
  roles: [
    {
      id: 1,
      name: "授课老师",
      privileges: [/0004[0-9]{4}/],
    },
    {
      id: 2,
      name: "助教老师",
      privileges: [/0003[0-9]{4}/],
    },
    {
      id: 3,
      name: "巡课老师",
      privileges: [/00250001/],
    },
  ],
};
