<template>
  <div class="login-container">
    <!-- <div @click="login">登录页面</div> -->
    <div class="login-box">
      <div class="left-img box-container"></div>
      <div class="right-container box-container">
        <div class="title">教师端登录</div>
        <div class="tip" v-if="showTip">
          <img class="icon-tip1" src="@/assets/login/icon-tip1.png" />
          <div class="content">{{ errNotice }}</div>
          <img
            class="icon-tip2"
            src="@/assets/login/icon-tip2.png"
            @click="hideTip"
          />
        </div>
        <div class="login-input">
          <el-select
            class="input-username"
            v-model="selectedRole"
            placeholder="请选择角色"
            :style="[{ 'margin-top': inputTop + 'px' }]"
            style="width: 440px"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in roleConf"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            class="input-username"
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入手机号码"
            maxlength="11"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
            oninput="value=value.replace(/[^\d]/g,'')"
            @focus="hideTip"
            @input="onInputChange"
            style="margin-top: 37px"
          />
          <el-input
            class="input-password"
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
            @focus="hideTip"
            @input="onInputChange"
          />
        </div>
        <el-button
          class="login-submit"
          type="primary"
          :disabled="hideSubmit"
          @click="handleLogin"
          >登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getLogin } from "@/api/api.js";
import common from "@/common/common";
import functions from "@/common/functions";
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      passwordType: "password",
      showTip: false, //是否显示提示
      inputTop: 70, //70代表无tip提示的top值，18代表有tip提示的top值
      hideSubmit: true, //是否可以点击登录
      roleConf: common.roles,
      selectedRole: 1,
      errNotice: "",
    };
  },

  methods: {
    //输入框内容发生变化
    onInputChange() {
      if (
        this.loginForm.username.length === 11 &&
        this.loginForm.password.length > 0
      ) {
        this.hideSubmit = false;
      } else {
        this.hideSubmit = true;
      }
    },
    //隐藏tip
    hideTip() {
      this.showTip = false; //隐藏tip
      this.inputTop = 70; //修改top的值
    },
    //显示tip
    visibleTip(notice) {
      this.inputTop = 18;
      this.errNotice = notice || "账户或密码不正确";
      this.showTip = true;
    },
    // 登录
    handleLogin: function () {
      //参数
      let data = {
        username: this.loginForm.username,
        password: this.$md5(this.loginForm.password),
        type: 1,
      };
      //链接
      getLogin(data)
        .then((res) => {
          // console.log(res);
          //登录成功
          if (res.data.code === 0) {
            let data = {
              id: res.data.data.id,
              username: res.data.data.username,
              name: res.data.data.name,
            };
            this.$store.commit("SET_USER_INFO", data);
            // console.log(this.$store.state.userInfo);
            // 根据选择的角色判断权限
            let selectedRole = this.roleConf.filter(
              (item) => item.id === this.selectedRole
            );
            selectedRole = selectedRole[0];
            if (
              functions.affirmPri(
                selectedRole.privileges,
                Object.keys(res.data.data.privilege)
              )
            ) {
              console.log(`拥有${selectedRole.name}权限`);
              this.$store.commit("SET_ROLE", selectedRole);
              //跳转页面
              this.$router.push({
                path: "/HomePage/home",
              });
            } else {
              // 弹出权限错误弹窗
              this.visibleTip(
                "您的账号权限和角色不匹配，请重新输入账号或选择角色"
              );
            }
          }
          //密码错误或未注册账号
          else {
            this.visibleTip();
          }
        })
        .catch((e) => {
          //链接失败
          this.visibleTip();
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss">
.login-container {
  //整体界面
  height: 100%;
  width: 100%;
  min-width: 1000px;
  min-height: 600px;
  background-image: url("../../assets/login/bg.png");
  background-size: 100% 100%;
  overflow: scroll;

  .login-box {
    //登录框
    width: 950px;
    height: 536px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 24px 0 rgba(151, 151, 151, 0.5);

    .box-container {
      //登录框
      float: left;
      height: 536px;
    }

    .left-img {
      //左边背景
      width: 350px;
      background-image: url("../../assets/login/left.png");
      background-size: contain;
    }

    .right-container {
      //右边操作框
      width: 600px;
      background-color: #ffffff;

      .title {
        //标题
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        margin-left: 80px;
        margin-top: 67px;
      }
      .tip {
        //提示框
        // 整体框
        height: 40px;
        background: #fff1f0;
        border-radius: 2px;
        border: 1px solid #ffccc7;
        margin-left: 80px;
        margin-right: 67px;
        margin-top: 12px;
        //设置内容居中
        display: flex;
        align-items: center;

        .icon-tip1 {
          width: 14px;
          height: 14px;
          margin-left: 16px;
          margin-right: 8px;
        }
        .content {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC, serif;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
        .icon-tip2 {
          width: 12px;
          height: 12px;
          margin-left: auto;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      .login-input {
        //登录输入框
        margin-left: 80px;
        margin-right: 80px;

        .input-password {
          //密码框
          margin-top: 37px;
        }
        .el-input {
          display: inline-block;
          font-size: 16px;
          line-height: 22px;
          font-family: PingFangSC-Regular, PingFang SC, serif;

          input {
            border: 0;
            border-radius: 0px;
            border-bottom: 1px solid #aeb7c6;
            padding: 0;
            -webkit-appearance: none;
            color: #333333;
            height: 40px;
            caret-color: black;

            &:-webkit-autofill {
              //选择记录
              box-shadow: 0 0 0px 1000px white inset !important;
              -webkit-text-fill-color: #333333 !important;
            }
          }
        }
      }
      .login-submit {
        //登录按钮
        margin-top: 67px;
        margin-left: 182px;
        width: 230px;
        height: 46px;
        background-color: #232428; //可以被点击的颜色
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        &:disabled {
          background-color: #dfdfdf; //不可以被点击的颜色
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
/deep/ .filter-form {
  height: 32px;
  line-height: 32px;
  margin-bottom: 20px;
  .el-form-item {
    height: 32px;
    margin-right: 20px;
  }
  &-week {
    line-height: 32px;
    height: 32px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .el-input--suffix {
    height: 32px;
  }
  .el-form-item__content {
    line-height: 32px;
  }
  &-status {
    height: 32px;
    width: 154px;
    font-size: 14px;
    font-weight: 400;

    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
    }
    .el-input__suffix {
      height: 32px;
    }
    .el-input__icon {
      line-height: 32px;
    }
  }
  &-btn {
    height: 32px;
    background: #26272b;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    border-radius: 2px;
    padding: 5px 21px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
/deep/ .el-select-dropdown__item.selected {
  color: #3a3a3a;
  font-family: PingFangSC-Medium, PingFang SC, serif;
}
/deep/ .el-select-dropdown__item {
  color: #999999;
  font-weight: 100;
  font-family: PingFangSC-Regular, PingFang SC, serif;
}
/deep/ .el-popper.el-select-dropdown {
  left: 0 !important;
}
</style>
