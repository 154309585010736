export default {
  // 传入正则数组和用户权限数组，判断是否可以通过
  affirmPri: function (passArr, priArr) {
    if (!Array.isArray(passArr) || !Array.isArray(priArr)) {
      return false;
    }
    return passArr.some((item) => {
      return priArr.some((one) => {
        return item.test(one);
      });
    });
  },
};
